:root {
  --primary: #3f51b5;
}

body,
#root {
  background-color: #f8f8f8;
  height: 100vh;
}

h3 {
  font-size: 24px;
  margin: 0;
}

div.MuiFilledInput-root {
  background-color: #f8f8f8;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 100, 0.1) 0 0 0 1px;

  &::before {
    border-radius: 0 0 4px 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }

  &:hover {
    &::before {
      border-bottom: 1px solid rgba(0, 0, 0, 0);
    }
  }
}
